<!--  -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
        <el-select v-model="dataInfo.value" placeholder="请选择拦河闸" size="mini">
            <el-option
              v-for="(item,index) in waterList"
              :key="item.deviceAddress"
              :label="index+1+' '+item.siteName"
              :value="item.siteName">
            </el-option>
          </el-select>
        <!-- <el-input
          placeholder="请输入拦河闸名称"
          v-model="dataInfo.value"
        ></el-input> -->
        <el-date-picker
          :editable="false"
          v-model="dataInfo.time"
          type="date"
          placeholder="选择日期"
          class="check_date"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-button type="primary" @click="handleSearch(0)">查询</el-button>
        <el-button type="info" @click="handleSearch(1)">重置</el-button>
      </div>
      <div class="menu_right">
        <el-button type="success" @click="handleExport">导出</el-button>
      </div>
    </div>
      <div class="lineChart" id="lineChart"></div>
    <div ref="scroll" @mousewheel="MouseWheel">
      <el-table
        @cell-click="handleUpdate"
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        :data="tableData"
        stripe
        fit
        style="width: 100%;"
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
      <el-table-column type="index" label="序号"> </el-table-column>
        <el-table-column
          prop="rainName"
          label="拦河闸名称"
           width="90"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          style="cursor:pointer;"
          v-for="(item, i) in DateList"
          :key="i"
          width="55"
          :prop="`dayRains[${i}].rainfall`"
          :label="`${item}`"
        >
        </el-table-column>
        <el-table-column width='120'  label="总降雨量(mm)">
          <template scope="scope">
            {{ scope.row.sumRainfall }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      class="border-r-8 role-dialog"
      :visible.sync="addShow"
      width="calc(388/1920*100vw)"
      @close="closeBox"
    >
      <div slot="title" class="dialogTitle t-x font-s-18">数据修正</div>
      <div class="contentAdd" style="margin-bottom: 10px;">
        <el-form :model="dateInfo" ref="dateInfo" :rules="rules">
          <el-form-item prop="rainName" style="margin-top:calc(25/1080*100vh)">
            <span class="label_r">站点</span>
            <el-input
              placeholder=""
              readonly
              v-model="dateInfo.rainName"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="time"
            class="mgb"
            style="margin-top:calc(25/1080*100vh)"
          >
            <span class="label_r">日期</span>
            <el-input
              placeholder=""
              readonly
              v-model="dateInfo.time"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="rainHour"
            class="mgb"
            style="margin-top:calc(25/1080*100vh)"
          >
            <span class="label_r">时间</span>
            <el-input
              placeholder=""
              readonly
              v-model="dateInfo.rainHour"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="rainfall"
            class="mgb"
            style="margin-top:calc(25/1080*100vh)"
          >
            <span class="label_r">降雨量</span>
            <el-input
              placeholder="请输入降雨量"
              maxlength="4"
              v-model="dateInfo.rainfall"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addShow = false" class="guanBi">关闭</el-button>
        <el-button
          @click="handleSave"
          type="primary"
          style="margin-left:calc(30/1920*100vw)"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- <el-pagination
      background
      layout="total, prev, pager, next, jumper"
      :total="pagination.total"
      @current-change="handleCurrent"
      :current-page="pagination.page"
    >
    </el-pagination> -->
  </div>
</template>

<script>
import { rainDay, addRainData, updRainDate,siteGetSiteAll,YearsList } from "../../../api/index";
import { rainFall } from "./validate";
var isRainFall = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入降雨量"));
  } else if (!rainFall(value)) {
    callback(new Error("请输入正确的降雨量"));
  } else {
    callback();
  }
};
let scrollNum = 0;
export default {
  components: {},
  data() {
    return {
      rules: {
        rainfall: [
          {
            required: true,
            message: "请输入降雨量",
            trigger: "blur"
          },
          {
            required: true,
            message: "请输入正确的降雨量",
            trigger: "blur",
            validator: isRainFall
          }
        ]
      },
       waterList: [],//拦河闸下拉
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      loading: true,
      DateList: [
        '8时',
        '9时',
        '10时',
        '11时',
        '12时',
        '13时',
        '14时',
        '15时',
        '16时',
        '17时',
        '18时',
        '19时',
        '20时',
        '21时',
        '22时',
        '23时',
        '24时',
        '1时',
        '2时',
        '3时',
        '4时',
        '5时',
        '6时',
        '7时'
      ],
      dataInfo: {
        time: "",
        value: ""
      },
      pagination: {
        // 分页
        size: 10,
        page: 1,
        total: 0
      },
      time: "",
      tableData: [],
      dateInfo: {
        id: "",
        rainHour: "",
        rainName: "",
        time: "",
        rainfall: "",
        rainYear: "",
        rainMonth: "",
        rainDay: "",
        deviceAddress: ""
      },
      addShow: false,
      loading: false,
      searchTime: "" ,//搜索时输入的时间
       nowLevelList: [] // 后台返回的今年流量表
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 统计图
        init ( now,name, max, min) {
      const ImgIcon = require('@/assets/images/dot.png')
      const nowImgIcon = require('@/assets/images/nowDot.png')
      const lineChart = document.getElementById('lineChart')
      const myChart = this.$echarts.init(lineChart)
      const option = {
        legend: {
          show: true,
          right: '5%',
          top: '0%',
          orient: 'horizontal',
          icon: 'rect',
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            fontSize: 14,
          }
        },
        xAxis: {
         // name:"H",
           nameTextStyle:{
            fontSize:10,  
        },
          type: 'category',
          data: this.DateList,
          axisLabel: {
            show: true,
            textStyle: {
              fontWeight: 'bold'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisTick: {
            show: false
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: name+' <br> {b0} ：{c0} mm'
          // formatter: function (params) {
          //   //console.log(params);
          //   if (params.length == 1) {
          //     return params[0].name + '<br/>' + params[0].marker + params[0].seriesName + ': ' + params[0].value
          //   } else {
          //     return params[0].name  + '<br/>' + params[0].marker + params[0].seriesName + ': ' + params[0].value + '<br/>' + params[1].marker + params[1].seriesName + ': ' + params[1].value
          //   }
          // }
        },
        yAxis: {
          name:"",//单位
           nameTextStyle:{
            fontSize:10,  
        },
          type: 'value',
          min: min,
          max: max,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontWeight: 'bold'
            }
          }
        },
        grid: {
          top: 30,
          x: 58,
          x2: 30,
          y2: 35
        },
        series: [
          {
          //  name: '今年',
            data: now,
            type: 'line',
            showSymbol: true, // 是否默认展示圆点
            // symbol: `image://${nowImgIcon}`, // 设定为实心点
            // symbolSize: 16, // 设定实心点的大小
            itemStyle: {
              normal: {
                // color: '#40F58D',
                // width: 8,
                // lineStyle: {
                //   color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{ // 1代表上面
                //     offset: 0,
                //     color: '#FFA415'
                //   }, {
                //     offset: 1,
                //     color: '#40F58D'
                //   }]),
                //   width: 4
                // }
              }
            }
          }]
      }
      myChart.setOption(option)
      // 图表自适应
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    getData (val) {
    //   for(let i=0;i<this.tableData.length;i++){
    //  //   console.log(this.tableData[i]);
    //     for(let j=0;j<this.tableData[i].dayRains.length;j++){
    //      this.nowLevelList.push(this.tableData[i].dayRains[j].rainfall)
    //        console.log(this.tableData[i].dayRains[j].rainfall);
    //     }
    //   }
    //   console.log(this.nowLevelList);
      //  const arr = lastNum.concat(nowNum)
      //   const max = Math.max(...arr)
      //   const min = Math.min(...arr)
      //   this.tableData 
      //   this.init(
      //     this.nowLevelList.map(item => item.level),max, min
      //     )
      // YearsList(val).then(res => {
      //   console.log(res)
      //   // 去年流量表
      //   // 今年流量表
      //   this.nowLevelList = res.data.nowLevel
      //   const lastNum = res.data.lastLevel.map(item => item.level)
      //   const nowNum = this.nowLevelList.map(item => item.level)
        
        
      // })
    },
    MouseWheel(e) {
      scrollNum += e.deltaY;
      this.$refs.scroll.children[0].children[2].scrollTo(scrollNum, 0);
    },
    handleSearch(type) {
      this.loading = true;
      if (type == 1) {
        this.$set(this.dataInfo, "time", "");
        this.$set(this.dataInfo, "value",this.waterList[0].siteName);
        this.pagination.page = 1;
        this.getList();
      } else {
        let { value, time } = this.dataInfo;
        this.time = time;
        // 搜索时输入的时间
        this.searchTime = time;
        rainDay({
          page: 1,
          size: 10,
          rainName: value,
          time
        }).then(res => {
          console.log(res);
          this.loading = false;
          
            const arr = res.data.list.find((name)=>{
            return  name.rainName==value
            })
            const name =arr.rainName
            const nowList = arr.dayRains.map(item => item.rainfall)
            const max = Math.max(...nowList)
            const min = Math.min(...nowList)
            this.init(
            nowList,name,max, min
              )
          this.tableData = res.data.list;
        //   this.pagination.total = res.data.total;
        //   this.pagination.pageNum = res.data.pageNum;
        });
      }
    },
    handleCurrent(pages) {
      this.loading = true;
      this.pagination.page = pages;
      this.getList();
    },
    getList() {
      this.loading = true;
      let { page, size } = this.pagination;
      let date = new Date();
      this.searchTime =
        date.getFullYear() +
        "-" +
        this.nums(date.getMonth() + 1) +
        "-" +
        this.nums(date.getDate());
      this.time =
        date.getFullYear() +
        "-" +
        this.nums(date.getMonth() + 1) +
        "-" +
        this.nums(date.getDate());
      this.$set(this.dataInfo, "time", this.time);
      // this.dataInfo.time = this.time;
      rainDay({
        page,
        size,
        time: this.time
      }).then(res => {
        this.loading = false;
         this.tableData = res.data.list;
        // this.pagination.total = res.data.total;
         const arr = this.tableData[0].dayRains
          const name =res.data.list[0].dayRains[0].rainName
         const nowList = arr.map(item => item.rainfall)
        const max = Math.max(...nowList)
        const min = Math.min(...nowList)
        this.init(
         nowList,name,max, min
          )
         //this.getData()
      });
    },
    setList() {
      this.loading = true;
      let { page, size } = this.pagination;
      let date = new Date();

      // this.dataInfo.time = this.time;
      rainDay({
        page,
        size,
        time: this.dateInfo.time
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
      });
    },
    // 导出
    handleExport() {
      let { value, time } = this.dataInfo;
      if (time !== "") {
        fetch(
          this.$axios.defaults.baseURL +
            `rainfallRegime/exportUpdData?time=${time}&rainName=${value}`,
          {
            headers: {
              token: localStorage.getItem("token")
            }
          }
        )
          .then(res => {
            return res.blob();
          })
          .catch(err => {
            console.log(err);
          })
          .then(e => {
            console.log(e);
            const a = document.createElement("a");
            a.href = URL.createObjectURL(e);
            a.download = "雨情日报表.xlsx";
            a.click();
          });
      } else {
        fetch(
          this.$axios.defaults.baseURL +
            `rainfallRegime/exportUpdData?time=${time}&rainName=${value}`,
          {
            headers: {
              token: localStorage.getItem("token")
            }
          }
        )
          .then(res => {
            return res.blob();
          })
          .catch(err => {
            console.log(err);
          })
          .then(e => {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(e);
            a.download = "雨情日报表.xlsx";
            a.click();
          });
      }
    },
    // 点击单元格
    handleUpdate(row, col) {
      var num = col.property.replace(/[^0-9]/gi, "");
      if (row[col.property.slice(0, 8)].length - 1 < num) {
        return;
      } else {
        var item = row[col.property.slice(0, 8)][num];
        // 获取当前的日期
        let date = new Date();
        // 站点
        this.$set(this.dateInfo, "rainName", item.rainName);
        // 时间
        this.$set(this.dateInfo, "rainHour", item.rainHour + "时");
        // 设备地址码
        this.$set(this.dateInfo, "deviceAddress", row.deviceAddress);
        // 获取id
        this.$set(this.dateInfo, "id", item.id);
        // 如果存在id就是编辑
        if (this.dateInfo.id !== null) {
          this.$set(this.dateInfo, "rainfall", item.rainfall);
        }
        // 获取当前小时
        var Hours = date.getHours();
        // 获取当前时间  年月日
        var newDates =
          date.getFullYear() +
          "-" +
          this.nums(date.getMonth() + 1) +
          "-" +
         this.nums(date.getDate())
        // 获取当前点击的时间
        var clickHours = this.dateInfo.rainHour.split("时")[0];
        // 当时间小于 8点的时候
        if (this.searchTime == "") {
          if (Hours >= 8) {
            if (clickHours < 8) {
              clickHours = clickHours + "24";
              if (Hours > clickHours) {
                this.addShow = true;
              } else {
                this.addShow = false;
              }
            } else {
              if (Hours >= clickHours) {
                this.addShow = true;
              }
            }
          } else {
            if (clickHours < 8) {
              if (Hours >= clickHours) {
                this.addShow = true;
              }
            } else {
              clickHours = clickHours - "24";
              if (Hours >= clickHours) {
                this.addShow = true;
              }
            }
          }
          this.$set(
            this.dateInfo,
            "time",
            date.getFullYear() +
              "-" +
              this.nums(date.getMonth() + 1) +
              "-" +
              this.nums(date.getDate())
          );
          this.$set(this.dateInfo, "rainYear", date.getFullYear().toString());
          this.$set(
            this.dateInfo,
            "rainMonth",
            this.nums((date.getMonth() + 1).toString())
          );
          this.$set(this.dateInfo, "rainDay", date.getDate().toString());
        } else {
          this.addShow = true;
          this.$set(this.dateInfo, "time", this.searchTime);
          this.$set(this.dateInfo, "rainYear", this.searchTime.split("-")[0]);
          this.$set(this.dateInfo, "rainMonth", this.searchTime.split("-")[1]);
          this.$set(this.dateInfo, "rainDay", this.searchTime.split("-")[2]);
          this.$set(this.dateInfo, "rainfall", item.rainfall);
          this.searchTime = this.searchTime.split("-");
          this.searchTime =
            this.searchTime[0] +
            "-" +
            this.searchTime[1] +
            "-" +
            this.searchTime[2];
          if (this.searchTime < newDates == true) {
            this.addShow = true;
          } else {
            // 当前时间大于8点
            if (Hours >= 8) {
              if (clickHours < 8) {
                clickHours = clickHours + "24";
                if (Hours >= clickHours) {
                  this.addShow = true;
                } else {
                  this.addShow = false;
                }
              } else {
                console.log(Hours, clickHours);
                if (Hours >= clickHours) {
                  this.addShow = true;
                } else {
                  console.log(2222);
                }
              }
            } else {
              if (clickHours < 8) {
                if (Hours >= clickHours) {
                  this.addShow = true;
                }
              } else {
                clickHours = clickHours - "24";
                if (Hours >= clickHours) {
                  this.addShow = true;
                }
              }
            }
          } // // 如果时间今天
        }
      }
    },
    // 月份小于10自动加0
    nums(s) {
      return s < 10 ? "0" + s : s;
    },
    handleSave() {
      let {
        id,
        rainHour,
        rainName,
        time,
        rainfall,
        rainYear,
        rainMonth,
        rainDay,
        deviceAddress
      } = this.dateInfo;
      rainHour = rainHour.split("时")[0];
      if (id == null) {
        this.$refs["dateInfo"].validate(valid => {
          if (valid) {
            addRainData({
              rainHour,
              rainName,
              time,
              rainfall,
              rainYear,
              rainMonth,
              rainDay,
              deviceAddress
            })
              .then(res => {
                this.$message.success(res.message);
                this.addShow = false;
                this.setList();
              })
              .catch(err => {
                this.$message.error(err.message);
              });
          }
        });
      } else {
        this.$refs["dateInfo"].validate(valid => {
          if (valid) {
            updRainDate({
              id,
              rainfall
            })
              .then(res => {
                this.$message.success(res.message);
                this.addShow = false;
                this.setList();
              })
              .catch(err => {
                this.$message.error(err.message);
              });
          }
        });
      }
    },
    closeBox() {
      this.$refs["dateInfo"].resetFields();
    }
  },
  created() {
    siteGetSiteAll().then(res=>{
          this.waterList=res.data
         this.dataInfo.value=this.waterList[0].siteName
      })
    this.getList();
   // this.getData()
  },
  mounted() {
  }
};
</script>
<style lang="scss" scoped>
.lineChart{
  width: 100%;
  height: 230px;
  margin: 0px auto;
}
::v-deep .el-table {
  height: calc(550 / 1080 * 100vh) !important;
  margin-bottom: 15px !important;
  background: transparent;
}
::v-deep .el-dialog {
  .el-icon-circle-close {
    display: none !important;
  }
  .el-input,
  .el-textarea,
  .el-select {
    width: calc(244 / 1920 * 100vw);
  }
  .images {
    height: calc(60 / 1080 * 100vh);
    i {
      width: calc(59 / 1920 * 100vw);
      height: calc(59 / 1080 * 100vh);
      border: 1px solid #585858;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  ::v-deep .el-input.search_input {
    width: calc(175 / 1920 * 100vw);
  }
}
::v-deep .el-form-item__content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-start;
}

::v-deep {
  .el-table__row {
    cursor: pointer;
    .el-table_1_column_1 {
      cursor: default;
    }
    .el-table_1_column_26 {
      cursor: default;
    }
  }
}
::v-deep .el-dialog {
  background: #4073b6;
  border-radius: 16px;
  width: calc(388 / 1920 * 100vw) !important;
  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }
  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;
    .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }
  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }
  }
  .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }
  .label_r {
    color: #666;
    margin-left: 20px !important;
    text-align: left;
    width: calc(70 / 1920 * 100vw);
    // margin-bottom: 10px;
  }
}
</style>
